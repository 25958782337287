import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { IPage } from "@/models/IPage";
import WebPageService from "@/services/WebPageService";
import { Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { VueEditor } from "vue2-editor";
import Multiselect from "vue-multiselect";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    ValidationObserver,
    VueEditor,
    Multiselect,
  },
})
export default class WebPageModule extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  userId = 0;
  pages: any;
  totalItems = 0;
  current_page = 1;
  search: string = "";
  pageHeading: string = "";
  totalKey = 0;
  totalcharacterDis = 0;
  isPageTitle = false;
  isPageName = true;
  pagename = "";
  refForm: any = (this as any).$refs.form;
  pageType: any = [];
  value: any;
  isFailed = false;
  failedMessage = "";
  pageContent = "";
  htmlEditor = true;
  activePrompt = false;
  acceptAlert = false;
  pageId = 0;
  @Auth.Getter
  private getBranchId!: string;

  $refs!: {
    validationProviderRef: InstanceType<typeof ValidationProvider>;
    validationObserverRef: InstanceType<typeof ValidationObserver>;
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  page: IPage = {
    pageid: 0,
    pagename: "",
    pagetitle: "",
    pagekeywords: "",
    pagediscription: "",
    pagetags: "",
    content1: "",
    content2: "",
    content3: "",
    content4: "",
    pageHeading: "",
    isPageShow: true,
    pageType: "",
    extra1: "",
  };

  charCountKey() {
    this.totalKey = this.page.pagekeywords.length;
  }

  onUrlSelection() {
    if (this.isPageName) {
      this.isPageTitle = !this.isPageTitle;
    } else if (this.isPageTitle) {
      this.isPageName = !this.isPageName;
    }
  }

  charCountDiscription() {
    this.totalcharacterDis = this.page.pagediscription.length;
  }

  openConfirm(pageId: number) {

    this.pageId = pageId;
    this.activePrompt = true;
  }

  deletePage(pageId: number) {
    (this as any).$Progress.start();

    WebPageService.deletePage(this.pageId, this.getBranchId).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Web Page",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Web Page",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
          (this as any).$Progress.finish();
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Blog Category",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  async created() {
    (this as any).$Progress.start();
    var result = await WebPageService.getListofPages(1, this.getBranchId);
    this.pages = result.items;
    this.isloaded = true;
    this.totalItems = result.totalPages;
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId;

    this.pageType = await WebPageService.getPageServiceType(this.getBranchId);
    (this as any).$Progress.finish();
  }

  handleChangePage(page) {
    this.table.page = page - 1;
    //this.isloaded = false;
    this.setTable(this.table.page);
  }

  async setTable(pagenumber: number) {
    (this as any).$Progress.start();

    this.pages = [];
    //var result = await
    WebPageService.getListofPages(
      pagenumber,
      this.getBranchId,
      this.search
    ).then(
      (data) => {
        this.isloaded = false;
        this.pages = data.items;
        this.isloaded = true;
        this.totalItems = data.totalPages;
        (this as any).$Progress.finish();
        //this.current_page = 1
      }
    );

  }

  removeSpecialChars(str) {
    return str.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  @Watch("isPageName")
  onisPageNameChanged(value: string, oldValue: string) {
    if (this.isPageName) {
      this.isPageTitle = !this.isPageTitle;
      var tempVal = this.removeSpecialChars(this.pagename);
      this.page.pagename = tempVal.replace(" ", "-");
      this.page.pagename = this.page.pagename.replaceAll(" ", "-");
    }
  }

  @Watch("isPageTitle")
  onisPageTitleChanged(value: string, oldValue: string) {
    if (this.isPageTitle) {
      this.isPageName = !this.isPageName;
      var tempVal2 = this.removeSpecialChars(this.page.pagetitle);
      this.page.pagename = tempVal2.replace(" ", "-");
      this.page.pagename = this.page.pagename.replaceAll(" ", "-");
    }
  }

  @Watch("getBranchId")
  async onGetBranchIdChanged(value: string, oldValue: string) {
    await this.setTable(1);
  }

  @Watch("htmlEditor")
  onhtmlEditorChanged(value: string, oldValue: string) {
    this.htmlEditor = !this.htmlEditor
  }
  generateURL() {
    if (this.isPageName) {
      var tempVal = this.removeSpecialChars(this.pagename);
      this.page.pagename = tempVal.replace(" ", "-");
      this.page.pagename = this.page.pagename.replaceAll(" ", "-");
    } else if (this.isPageTitle) {
      var tempVal2 = this.removeSpecialChars(this.page.pagetitle);
      this.page.pagename = tempVal2.replace(" ", "-");
      this.page.pagename = this.page.pagename.replaceAll(" ", "-");
    }
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false;
    await this.setTable(this.current_page);
    //this.isloaded = true;
  }

  async handleSearch(searching) {
    (this as any).$Progress.start();
    if (searching.length > 3) {
      this.search = searching;
      WebPageService.getListofPages(1, this.getBranchId, this.search).then(
        (data) => {
          this.isloaded = false;
          this.pages = data.items;
          this.totalItems = data.totalPages;
          this.isloaded = true;
        }
      );
    } else if (searching.length == 0) {
      WebPageService.getListofPages(1, this.getBranchId).then((data) => {
        this.isloaded = false;
        this.pages = data.items;
        this.totalItems = data.totalPages;
        this.isloaded = true;
      });
    }
    (this as any).$Progress.finish();
    //
  }

  onBack() {
    //this.setTable(1)
    this.active = !this.active;
  }

  async onEdit(pageId: number) {
    (this as any).$Progress.start();
    this.page = await WebPageService.getPageByPageId(pageId, this.getBranchId);
    this.pagename = this.page.extra1;
    this.value = this.page.pageType;
    this.pageContent = this.page.content1;
    console.log(this.page);
    this.pageHeading = "Update Page";
    this.active = !this.active;
    (this as any).$Progress.finish();
  }

  onOpen() {
    this.pageHeading = "Add New Page";
    this.active = !this.active;
    this.page = {
      pageid: 0,
      pagename: "",
      pagetitle: "",
      pagekeywords: "",
      pagediscription: "",
      pagetags: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      pageHeading: "",
      isPageShow: false,
      pageType: "",
      extra1: "",
    };
  }

  async onSubmit() {
    const isValid = await (this as any).$refs.observer.validate();
    if (!isValid) {
      alert(isValid);
    }
    (this as any).$Progress.start();
    this.loading = true;
    this.page.pageType = this.value;
    this.failedMessage = "";
    this.isFailed = false;
    this.page.extra1 = this.pagename;
    this.page.content1 = this.pageContent;
    WebPageService.savePage(this.page, this.getBranchId).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Web Page",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
          // this.page = {
          //   pageid: 0,
          //   pagename: "",
          //   pagetitle: "",
          //   pagekeywords: "",
          //   pagediscription: "",
          //   pagetags: "",
          //   content1: "",
          //   content2: "",
          //   content3: "",
          //   content4: "",
          //   pageHeading: "",
          //   isPageShow: false,
          //   pageType: "",
          //   extra1: "",
          // };
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Web Page",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Web Page",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
